(function($) {
    $(function() {
        // This is needed because of a bug in the version of  jQuery we've used.
        // When we call $(document).trigger('cart.updated'), there's a block of
        // code that checks if 'cart' in document and if so tries to call it as
        // a function. Becase we have many form[name="cart"] on the page,
        // document.cart does exist, but isn't a function, jQuery tries to call
        // it, and bails out. We shouldn't be relying on these shortcut
        // references as they are non-standard so removing it here should
        // suffice. Hopefully there aren't any unforseen issues. DH
        try {
            delete document.cart;
        }
        catch (e) {
            document.cart = [][0];
        }
    });

    /**
     * copied from estee_base/js/shared/loyalty_offer.js
     * Helper function to get the raw contents of an JS inline template, and optionally interpolate
     * the html using Mustache
     */
    getTemplateContent = function(key, data) {
        var undef;

        // Sanitize the incoming data
        data = data || false;

        // Get the html content of the template
        var html = $("script.inline-template[path='" + key + "']").html();

        if (!html || html.length === 0) {
            return $();
          }

        // If there's no data object passed in, then just return a basic jquery element
        if (data == false || !$.isFunction(Mustache.render)) {
            return $(html);
        }

        // We have html and data, which means we should use Mustache to render the output
        return $(Mustache.render(html, data));
    };

    // don't show loyalty pop up on some URLs - these are set in the CMS
    var template = getTemplateContent('loyalty_disable_urls_uk');
    var excludedURLs = template.val();
    var excludedURLCheck = template.hasClass('js-excluded-urls');

    if( excludedURLCheck && excludedURLs.length > 0 ) {
        var URLarray = excludedURLs ? excludedURLs.split(',') : [];
        var pathURL = window.location.pathname;

        // if we're on one of the pages, set popup cookie
        if(URLarray.length > 0 && URLarray.indexOf(pathURL) !== -1){
            $.cookie('elist15', '1', {
                path: '/'
            });
        }
    }

    // pwp
    var qualified = false;
    var prodPath;
    var prodImage;

    $(document).bind("RPC:RESULT", function(event, response, request) {
        var data = $.parseJSON(response.responseText);
        var pwpData;

        if(request.params){
          var params = request.params[0];
        }else{
          return;
        }

        // skip if we've already seen the popup
        if (+$.cookie('pwpPopupSeen')) {
            return;
        }

        // skip non-cart submits
        if (request.method != 'rpc.form' || params._SUBMIT != 'cart') {
            return;
        }

        // skip if we have no data
        if (!(data || data[0] || data[0].result || data[0].result.data)) {
            return;
        }
        else {
            data = data[0].result.data;
        }

        // skip if we have no pwp data
        if (
            data.trans_data &&
            data.trans_data.pwp
        ) {
            pwpData = data.trans_data.pwp;
        }
        else {
            return;
        }

        // of if pwp isn't active
        if (!pwpData.active) {
            return;
        }

        qualified = pwpData.qualifiers > 0 ? true : false;
        prodPath = pwpData.pwp_path;
        prodImage = pwpData.pwp_popup_image;
    });

    $(document).bind('addToCart.success', function() {
        if (qualified) {
            generic.template.get({
                path: '/templates/pwp_october2016.tmpl',
                callback: function(html) {
                    var overlayContent = html.replace('PRODIMAGE',prodImage);
                    overlayContent = overlayContent.replace('PRODPATH',prodPath);
                    generic.overlay.launch({
                        content: overlayContent,
                        className: "pwp_october2016",
                        cssStyle: {
                            width: '644px',
                            height: '393px'
                        }
                    });
                }
            });

            $.cookie('pwpPopupSeen', 1, {
                path: '/'
            });

            qualified = false;
        }
    });
})(jQuery);
